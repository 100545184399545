body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
.ant-layout-header {
  height: 64px;
  padding: -1px 50px;
  line-height: 64px;
  background: #001529;
} */

.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
  background: #384d62;
}


/* .ant-table table {
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    background: #d9ddb0;

} */
.ant-tabs-content {
  display: inline-block !important;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated{
  display:flex !important;
}
/* .ant-drawer-right{
  right:0 !important;
} */
.ant-drawer{
  inset:none !important;
  left:auto !important;
}

.login-form {
    max-width: 400px;
   /* margin: 0px auto; */
    /* margin-top: 15% */
    /* display: flex; */
  }
  .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
  }

  .login-parent{
      display: flex;
  align-items: center;
  height:100vh;
  justify-content: center;
  }
 
  /* .test{
    margin-left: 40%;
    margin-top: 15%;
  } */
  .form-v1 .ant-row {
    display: block !important;
    /* flex-flow: row wrap; */
  }


.menu {
  float: right;
   margin-top: -62px;
   margin-right: -40px;
}

@media screen and (max-width: 1000px) {
  .menu {
      visibility: hidden;
  }
}

/* #components-layout-demo-fixed .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.ant-layout-header {
  height: 64px;
  line-height: 64px;
  background: #001529;
} */
.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
background-color: rgba(192,223,205,0.2); /*Overriden property*/
}
#components-layout-demo-fixed-sider .logo {
height: 32px;
background: rgba(255, 255, 255, 0.2);
margin: 16px;
}
.ant-menu{
font-size: 16px !important;
font-weight: bold !important;
}
/* .ant-layout-header{
padding:0 25px !important;
} */
.themeLayout  > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  margin-top: 18px !important;
}
.theme1 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #292961 !important;
  color:#fff !important;
}
.theme2 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #4b4ba3 !important;
  color:#fff !important;
}
.theme3 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #1a3652 !important;
  color:#fff !important;
}
.theme4 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #2261a1 !important;
  color:#fff !important;
}
.theme5 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #0d4524 !important;
  color:#fff !important;
}
.theme6 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #156b39 !important;
  color:#fff !important;
}
.theme7 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #691a16 !important;
  color:#fff !important;
}
.theme8 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #a62e21 !important;
  color:#fff !important;
}
.theme9 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #2e2e2e !important;
  color:#fff !important;
}
.theme1 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #292961 !important;
  color:#fff !important;
}
.theme2 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #4b4ba3 !important;
  color:#fff !important;
}
.theme3 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #1a3652 !important;
  color:#fff !important;
}
.theme4 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #2261a1 !important;
  color:#fff !important;
}
.theme5 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #0d4524 !important;
  color:#fff !important;
}
.theme6 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #156b39 !important;
  color:#fff !important;
}
.theme7 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #691a16 !important;
  color:#fff !important;
}
.theme8 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #a62e21 !important;
  color:#fff !important;
}
.theme9 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #2e2e2e !important;
  color:#fff !important;
}
/* .theme8 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #a62e21 !important;
  border: 1px solid white !important;
  color:#fff !important;
} */
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    color: #1890ff;
    margin: 0;
    padding: 0;
  }
  
  .link-button:hover,
  .link-button:focus {
  text-decoration: none;
  color: #40a9ff;
  }
/* body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .link {
    color: rgba(0, 0, 0, 0.65);
  }
  .link:hover {
     color: #1890ff;
  } */

 


.customTable tr {
    background-color: #011931 !important;
    color: black !important;
    text-align: center !important;
}

.customTable th {
    color: white !important;
    font-weight: bold !important;
    text-align: center !important;
}

.customTable th {
    background-color: #214972 !important;
    font-weight: bold !important;
}

.customTable tbody tr:hover td {
    background-color: #dedede !important;
    cursor: pointer;
    color: black !important;
}

.customTable tr:nth-child(even) {
    background: #7a8793 !important;
}

.customTable tr:nth-child(odd) {
    background: #bdc0c2 !important;
}
  .masterTable tr {
      background-color: #011931 !important;
      color: black !important;
      text-align: center !important;
  }

  .masterTable th {
      color: white !important;
      font-weight: bold !important;
      text-align: center !important;
  }

  .masterTable th {
      background-color: #214972 !important;
      font-weight: bold !important;
  }

  .masterTable tbody tr:hover td {
      background-color: #dedede !important;
      cursor: pointer;
      color: black !important;
  }

  .masterTable tr:nth-child(even) {
      background: #7a8793 !important;
  }

  .masterTable tr:nth-child(odd) {
      background: #bdc0c2 !important;
  }
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    color: #1890ff;
    margin: 0;
    padding: 0;
  }
  
  .link-button:hover,
  .link-button:focus {
  text-decoration: none;
  color: #40a9ff;
  }
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .rowContents{
    border: solid #bbb 1px;
    padding: 10px 5px 10px 10px;
   width:40%;
   
   margin: 10px;
   background: #fff
  }
  
  .rowLabel{
    padding:0 0 0 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px
  }
  
  .innerdiv{
    padding: 10px 0 10px 50px ;
    background: #fff
  }
  
  .selectdiv{
    width: 350px  ;
    padding: 10px 0 0 20px;
    margin-left: 20px
  }
  
  .ant-input {
    /* width: 50px; */
    margin: 0 8px 8px 0;
  }
  
  .editInputs{
    width: 100%;
    padding: 4px 11px;
    background-color:#fff;
    height: 32px;
     
  
  }
  
.steps-content {
    margin-top: 0px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding: 80px 30px;
  }
  
  .steps-action {
    margin-top: 24px;
  }

 .importCard .ant-card-head{
    color: #000 !important;
    font-weight: bold;
   
    border-bottom: 2px solid #000 ;
}
.ant-card-body{
    padding:10px !important;
    font-weight: bold;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    font-weight: bold;;
}
.ant-progress-text {
  color: hwb(0 60% 37%) !important;
}

.progressbarcircle {
  width: auto !important;
  height: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;

}

.progressbarline {
  width: auto !important;
  height: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.ant-progress.ant-progress-line {
  width: 80% !important;

  /* margin: 15%  !important; */
}

.ant-progress.ant-progress-circle {
  margin-top: 5px;
  width: 40% !important;

  display: flex;
  justify-content: center;
  align-items: center;

  /* margin: 15%  !important; */
}

div[style].ant-progress-inner {
  width: 80% !important;
  height: auto !important;
}

/*  */
.ant-calendar-picker-input.ant-input {
  color: rgba(0, 0, 0, 0.9) !important;
}

.ant-calendar-range-picker-separator {
  color: rgba(0, 0, 0, 0.9) !important;
}

.ant-radio-group {
  color: rgba(0, 0, 0, 0.9) !important;
}

.ant-select-selection--single .ant-select-selection__rendered {
  color: rgba(0, 0, 0, 0.9) !important;
}

.warning-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: transparent;
  border-radius: 0 0 4px 4px;
}

.trendsFormLabel .ant-form-item-label>label {
  color: white;
  font-weight: bold;
}
.trendsFields {
  font-weight: bold;
  margin-top: 6px;
  font-size: 16px
}

.trendTable tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
}

.trendTable th {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.trendTable th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.trendTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.trendTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.trendTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}

.radioGroup .ant-radio-wrapper {
  color: white;
  font-size: 16px;
}

.radioGroup .ant-radio {
  margin: 5px;
}
@font-face { font-family: "Digital-Plain"; src: url(/static/media/digital-7.1e670d88.ttf); }

@font-face { font-family: "Roboto-Regular"; src: url(/static/media/Roboto-Regular.3e1af3ef.ttf); }

.ModalTable .ant-table-selection-column .ant-table-header-column{
    display: none !important;
}
.sldModalTable .ant-table-selection-column .ant-table-header-column{
    display: none !important;
}
.sldModalTable tr > th {
    text-align: center !important;
  font-weight: bold;
  font-size: 17px !important;
    color: white !important;
  }
  
  .sldModalTable tr:nth-child(odd) {
    background: #efefef !important;
  }
  .sldModalTable tr:nth-child(even) {
    background: #d9d9d9 !important;
  }
  
  .sldModalTable .ant-table-tbody > tr > td {
    text-align: center !important;
    padding: 16px 0px !important;
    font-weight: bold;
  font-size: 15px !important;
  }

  .sldModalTable th.table-column {
    background-color: #292961 !important;
    color: white !important;
  }
  .sldModalTable tr > th {
    background-color: #292961 !important;
    color: white !important;
  }

  
 /*themeing starts */

.theme1 th.table-column {
    background-color: #292961 !important;
    color: white !important;
  }
  .theme1 tr > th {
    background-color: #292961 !important;
    color: white !important;
  }
  .theme2 th.table-column {
    background-color: #4b4ba3 !important;
    color: white !important;
  }
  .theme2 tr > th {
    background-color: #4b4ba3 !important;
    color: white !important;
  }
  .theme3 th.table-column {
    background-color: #1a3652 !important;
    color: white !important;
  }
  .theme3 tr > th {
    background-color: #1a3652 !important;
    color: white !important;
  }
  .theme4 th.table-column {
    background-color: #2261a1 !important;
    color: white !important;
  }
  
  .theme4 tr > th {
    background-color: #2261a1 !important;
    color: white !important;
  }
  .theme5 th.table-column {
    background-color: #0d4524 !important;
    color: white !important;
  }
  .theme5 tr > th {
    background-color: #0d4524 !important;
    color: white !important;
  }
  .theme6 th.table-column {
    background-color: #156b39 !important;
    color: white !important;
  }
  .theme6 tr > th {
    background-color: #156b39 !important;
    color: white !important;
  }
  .theme7 th.table-column {
    background-color: #691a16 !important;
    color: white !important;
  }
  .theme7 tr > th {
    background-color: #691a16 !important;
    color: white !important;
  }
  .theme8 th.table-column {
    background-color: #a62e21 !important;
    color: white !important;
  }
  .theme8 tr > th {
    background-color: #a62e21 !important;
    color: white !important;
  }
  .theme9 th.table-column {
    background-color: #2e2e2e !important;
    color: white !important;
  }
  .theme9 tr > th {
    background-color: #2e2e2e !important;
    color: white !important;
  }
  /*themeing ends */

.ant-card-cover > * {
    width: 1200px;
    height: 1200px;
}
.ant-card-cover {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    background: #191a1d;
    
}

/* .ant-card-actions > li:not(:last-child) {
    border-right: 1px solid #191a1d;
    color: black;
} */

.ant-card-actions{
background: #fafafa;
zoom: 1;
list-style: none;
margin: 0;
padding: 0;
}

.iframe-wrap {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    background: #191a1d;
}

.cover-link {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: 0 !important;
    z-index: 1;
}
.visually-hidden {
    position: absolute !important;
    top: -9999px;
    left: -9999px;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    color: #1890ff;
    margin: 0;
    padding: 0;
  }
  
  .link-button:hover,
  .link-button:focus {
  text-decoration: none;
  color: #40a9ff;
  }
.ant-progress-text {
  color: #bfbfbf !important;
}
.progressbarcircle {
  width: auto !important;
  height: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressbarline {
  width: auto !important;
  height: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.ant-progress.ant-progress-line {
  width: 80% !important;

  /* margin: 15%  !important; */
}
.ant-progress.ant-progress-circle {
  margin-top:5px;
  width: 40% !important;

  display: flex;
  justify-content: center;
  align-items: center;

  /* margin: 15%  !important; */
}
div[style].ant-progress-inner {
  width: 80% !important;
  height: auto !important;
}
/*  */
.ant-calendar-picker-input.ant-input {
  color: rgba(0, 0, 0, 0.9) !important;
}
.ant-calendar-range-picker-separator {
  color: rgba(0, 0, 0, 0.9) !important;
}
.ant-radio-group {
  color: rgba(0, 0, 0, 0.9) !important;
}
.ant-select-selection--single .ant-select-selection__rendered {
  color: rgba(0, 0, 0, 0.9) !important;
}
.warning-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  /* color: rgba(0, 0, 0, 0.25);*/
  color: rgba(0, 0, 0, 0.25); 
  font-size: 14px;
  text-align: center;
  background: transparent;
  border-radius: 0 0 4px 4px;
}
/* .TableWidget tr {
  background-color: #0d1115 !important;
}
.TableWidget th {
  color: white !important;
}

.TableWidget th {
  background-color: #06050561 !important;
}

.TableWidget tbody tr:hover td {
  background-color: #06050561 !important;
  cursor: pointer;
} */

.TableWidget tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.2em;
  font-weight: bold;
}
.TableWidget th {
  color: #ccc6c6 !important;
  text-align: center !important;
}

.TableWidget th {
  background-color: rgba(85, 84, 84, 0.65) !important;  
  /* border-bottom: none !important; */
  font-size: 1.1em;
  font-weight: 800;
}

.TableWidget tbody tr:hover td {
  background-color: #ddd !important;
  cursor: pointer;
}

.TableWidget tbody td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
  /* border-bottom: 1px solid #8c8c8c !important; */
  background-color: #ccc6c6 !important;
  font-size: 13px;
}

.TableWidget ant-table-thead {
  background-color: grey !important;
  cursor: pointer;
}




.TableLightWidget tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.1em;
}
.TableLightWidget th {
  color: white !important;
  text-align: center !important;
}

.TableLightWidget th {
  background-color: rgba(85, 84, 84, 0.65) !important;  
  /* border-bottom: none !important; */
  font-size: 1.1em;
  font-weight: 800;
}

.TableLightWidget tbody tr:hover td {
  /* background-color: #06050561 !important; */
  cursor: pointer;
}

.TableLightWidget tbody td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
  /* border-bottom: none !important; */
  font-size: 1.1em;
}

.TableLightWidget ant-table-thead {
  background-color: grey !important;
  cursor: pointer;
}

.TableLightWidget .ant-table-placeholder{
  background-color: #595959 !important;
}
.TableWidget .ant-table-placeholder{
  background-color: #595959 !important;
}
.TableText{
 width: 100px;
 /* 
 Note: The Below 4 Lines are necessary for ellipsis to work.
 */
 display: block;/* Change it as per your requirement. */
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}

.barTable tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.1em;
}
.barTable th {
  color: white !important;
  text-align: center !important;
}

.barTable th {
  background-color: rgba(85, 84, 84, 0.65) !important;
  border-bottom: none !important;
  font-size: 1.1em;
  font-weight: 800;
}

.barTable tbody tr:hover td {
  background-color: #d9d9d9 !important;
  cursor: pointer;
}

.barTable tbody tr td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
 background-color: #f5f5f5 !important;
  font-size: 1.1em;
}



.notificationMain{
  width: 251px;
 display: inline-block;
  height: 61px;
  margin-bottom: 20px;
  margin-right:2%;
  margin-left:2%;
  background:grey;
  border-radius: 10px;
  position: relative;
}
.notificationDiv{
  margin-top:5px;
  background-color: #d9d9d9;
  border-radius: 10px;
  width:100px;
  float:left;
  text-align: center;
  height:80%;
  margin:2%;

}

.notificationDiv1{
  margin-top:5px;
  background-color: #d9d9d9;
  border-radius: 10px;
  width:100px;
  
  text-align: center;
  height:80%;
  margin-left:50%;
  margin-top:2%;
  
  
}
.notificationDiv span{
  font-size: 2.5em;
}
.notificationDiv1 span{
  font-size: 2.5em;
}


.reportParentTable th.table-column{
    background-color :#214972!important;
    text-align: center !important;
    color: white !important;
  }
  .reportParentTable tr.table-column{
    text-align: center !important;
   
  }
  .reportChildTable tr > td{
    text-align: center !important;
    font-weight: bold !important;
  }
  .reportParentTable tr > td{
    text-align: center !important;
    font-weight: bold !important;
  }
  .reportParentTable tr > th {
  text-align: center !important;
  background-color :#214972!important;
  color: white !important;
  font-weight: bold !important;
 }
 .reportChildTable tr > th {
  text-align: center !important;
  background-color :#214972!important;
  font-weight: bold !important;

  color: white !important;
 }
 .reportChildTable tr:nth-child(odd){ 
  background: #efefef;
}
.reportChildTable tr:nth-child(even){
  background: #d9d9d9;
} 
.reportParentTable tr:nth-child(odd){ 
  background: #efefef !important;
}
.reportParentTable tr:nth-child(even){
  background: #d9d9d9  !important;
} 

.reportParentTable  .ant-table-tbody > tr > td {
  padding: 16px 0px !important;  
}

.reportParentTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.reportParentTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.reportParentTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
.reportChildTable  .ant-table-tbody > tr > td {
  padding: 16px 0px !important;
  
}

.reportChildTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.reportChildTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.reportChildTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
/* even: "#d9d9d9",
odd: "#efefef", */
.onlineStatus{
color: white;
font-size: 25px;
font-weight: bold;
height: 50px;
width: 200px;
border-radius: 8px;
background-color:green;
text-align: center;
float: right;
padding:5px;
margin-right:15px;
margin-bottom: 5px;
}
.offlineStatus{
  color: white;
  font-size: 25px;
  font-weight: bold;
  height: 50px;
  width: 200px;
  float: right;
  border-radius: 8px;
  background-color:#ff7875;
  text-align: center;
  padding:5px;
  margin-right:15px;
  margin-bottom: 5px;

}
.headerForm{
 
  margin-bottom: 8px;
  border-radius: 6px;
  padding: 9px;
}
.headerForm .ant-form-item{
  color: #124b84;
  font-weight: bold;
 

}
.headerForm  .ant-form-item-label > label {
  color: #124b84;
  font-weight: bold;
}
.headerColumn{
  /* border: 1px solid #124b84; */

}

.footerRow{

}
.footerCol{


  padding: 5px;
}
.footerRow .ant-form-item{
  color: #124b84;
  font-weight: bold;

}
.footerRow  .ant-form-item-label > label {
  color: #124b84;
  font-weight: bold;
}
.norecords{
  color: #fff;
  background-color: darkgrey;
  text-align: center;
  font-size:18px;
  font-weight: bold;
}
.warning_reports {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: white;
  font-size: 14px;
  text-align: center;
  background: #e4dfdf;
  border-radius: 0 0 4px 4px;
  border-radius: 8px;
}
.configure{
font-size: 18px;
color: gray;
text-align: center;
margin-top: 50px;
font-weight: bold;
}
.container {
  font-size: 18px;
  font-weight: bold;
}
.containerDiv {
  padding: 4px;
  border: 1px solid gray;
  min-height: 43px;
  text-align: center;
  background-color: #e4dfdf;
}
.mainContainer{
  margin-bottom: 15px;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  
  z-index: 0 !important;

}

.ant-table-filter-dropdown .ant-dropdown-menu{
max-height: 300px !important;
z-index: 0 !important;
}


.Table tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
  width:"100%"
}

.Table th {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.Table th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.Table tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.Table tr:nth-child(even) {
  background: #7a8793 !important;
}

.Table tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
/* 
.Ant{
  color: white !important
}
.ant-btn
{
  background-color: #214972 !important;
  color:#fff !important;
  border-color: #1890ff !important;
}
.ant-btn ant-btn-primary{
  color: white !important
}

.ant-btn-primary{
  background-color: #214972 !important
}
.ant-btn-background-ghost.ant-btn-primary{
  color:white !important;
  background-color: #214972;
}
.ant-drawer-title{
  color:#214972 !important
}

.ant-btn ant-btn{
  color: white !important;
  background-color: #214972;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
  color:#214972
} */




.liquidgauge {
    width: 100%;
    height: 90%;
    float: left;
  }
  @media (max-width: 980px) {
    .liquidgauge {
        height: 200px;
    }
  }
  
  
  
  
  .root-chart {
    width: 100%;
    height: 90%;
    float: none;
  }
/*   
  .nav {
    text-align: center;
    margin: 10px;
  }
  
  a {
    text-decoration: none;
    background-color: #294D99;
    transition: 0.5s;
    color: white;
    padding: 6px 18px;
    border-radius: 20px;
    margin: 0 4px;
  }
  
  a:hover {
    background-color: #000000;
  }
   */
  #view-more {
    text-align: center;
    margin: 20px 0 80px 0;
  }
  /* canvas {
    height: 100% !important;
    width: 100% !important;
  } */
  .liquidgauge>div {
    height: 100% !important;
    width: 100% !important;
  }
.ant-card{
    color: #fff !important;
   
    font-size: 1.5em;
}
.ant-card-head{
    color: #fff !important;
    font-weight: bold;
   
    border-bottom: 2px solid #000 ;
}
 .ant-card-bordered {
    border: 0px solid #000;
} 
/* .headerdrag {
  background-color: #434343;
  border: 1px solid #595959;
} */

.headerdrag .editsettings {
  /* display: none; */
  background-color: #353535;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
}
.headerdrag:hover {
  background-color: #353535;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
}

.headerdrag:hover .editsettings {
  display: block;
}

.test {
  float: "right";
  color: "white";
  margin-top: "-25px";
  cursor: "pointer";
}

.headerdrag {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.headerdrag:active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(/static/media/Roboto-Medium.d0884059.ttf);
}

.darkTheme .ant-modal-content {
  background-color: #000 !important;
  color: #fff !important;
  height: 950px;
}
.lightTheme .ant-modal-content {
  background-color: #fff !important;
  color: #000 !important;
  height: 950px;
}
.darkTheme .ant-modal-body {
  position: absolute;
  height: 100%;
  width: 100%;
}

.lightTheme .ant-modal-body {
  position: absolute;
  height: 100%;
  width: 100%;
}
.darkTheme .ant-modal-header {
  background-color: #000;
  color: #fff !important;
}
.darkTheme .ant-modal-header .ant-modal-title {
  color: #bfbfbf !important;
  font-weight: bold;
  font-size: 1.5em !important;
  text-align: center;
}
.lightTheme .ant-modal-header .ant-modal-title {
  color: #000 !important;
  font-weight: bold;
  font-size: 2em !important;
  text-align: center;
}
.darkTheme .ant-modal-close-x {
  display: block;
  width: 75px !important;
  height: 77px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: center;
  color: #fff;
}
.lightTheme .ant-modal-close-x {
  display: block;
  width: 75px !important;
  height: 77px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: center;
  color: #000;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url(/static/media/Roboto-Medium.d0884059.ttf);
}

.spinner {
  display: flex;
  height: 800px;
  justify-content: center;
  align-items: center;
}


.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

/* Example stylistic flourishes */

.fileContainer {
  background: #ff4d4f;
  border-radius: 32px;
  float: left;
  padding: 0.5em;
  color: #ffffff;
  margin-left: 10px;
  width: 95px;
}

.fileContainer [type="file"] {
  cursor: pointer;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    z-index:0 !important;
}
.ant-calendar-picker-clear, .ant-calendar-picker-icon{
    z-index:0 !important;
}
/* .echarts-for-react div[style]{
    width:500px !important;
    height:300px !important;
} */
.rdrDateRangePickerWrapper.PreviewArea {
  z-index: 1;
  position: absolute;
  margin-left: -337px;
}

.datebutton {
  background-color: #121212;
  border: none;
  color: white;
  height: 38px;
  margin-right: 12px;
  font-family: "Roboto-Medium";
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(/static/media/Roboto-Medium.d0884059.ttf);
}

.reportParentTable th.table-column{
    background-color :#214972!important;
    text-align: center !important;
    color: white !important;
  }
  .reportParentTable tr.table-column{
    text-align: center !important;
   
  }
  .reportChildTable tr > td{
    text-align: center !important;
    font-weight: bold !important;
  }
  .reportParentTable tr > td{
    text-align: center !important;
    font-weight: bold !important;
  }
  .reportParentTable tr > th {
  text-align: center !important;
  background-color :#214972!important;
  color: white !important;
  font-weight: bold !important;
 }
 .reportChildTable tr > th {
  text-align: center !important;
  background-color :#214972!important;
  font-weight: bold !important;

  color: white !important;
 }
 .reportChildTable tr:nth-child(odd){ 
  background: #efefef;
}
.reportChildTable tr:nth-child(even){
  background: #d9d9d9;
} 
.reportParentTable tr:nth-child(odd){ 
  background: #efefef !important;
}
.reportParentTable tr:nth-child(even){
  background: #d9d9d9  !important;
} 

.reportParentTable  .ant-table-tbody > tr > td {
  padding: 16px 0px !important;  
}

.reportParentTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.reportParentTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.reportParentTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
.reportChildTable  .ant-table-tbody > tr > td {
  padding: 16px 0px !important;
  
}

.reportChildTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.reportChildTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.reportChildTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
/* even: "#d9d9d9",
odd: "#efefef", */
.onlineStatus{
color: white;
font-size: 25px;
font-weight: bold;
height: 50px;
width: 200px;
border-radius: 8px;
background-color:green;
text-align: center;
float: right;
padding:5px;
margin-right:15px;
margin-bottom: 5px;
}
.offlineStatus{
  color: white;
  font-size: 25px;
  font-weight: bold;
  height: 50px;
  width: 200px;
  float: right;
  border-radius: 8px;
  background-color:#ff7875;
  text-align: center;
  padding:5px;
  margin-right:15px;
  margin-bottom: 5px;

}
.headerForm{
 
  margin-bottom: 8px;
  border-radius: 6px;
  padding: 9px;
}
.headerForm .ant-form-item{
  color: #124b84;
  font-weight: bold;
 

}
.headerForm  .ant-form-item-label > label {
  color: #124b84;
  font-weight: bold;
}
.headerColumn{
  /* border: 1px solid #124b84; */

}

.footerRow{

}
.footerCol{


  padding: 5px;
}
.footerRow .ant-form-item{
  color: #124b84;
  font-weight: bold;

}
.footerRow  .ant-form-item-label > label {
  color: #124b84;
  font-weight: bold;
}
.norecords{
  color: #fff;
  background-color: darkgrey;
  text-align: center;
  font-size:18px;
  font-weight: bold;
}
.warning_reports {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: white;
  font-size: 14px;
  text-align: center;
  background: #e4dfdf;
  border-radius: 0 0 4px 4px;
  border-radius: 8px;
}
.configure{
font-size: 18px;
color: gray;
text-align: center;
margin-top: 50px;
font-weight: bold;
}
.container {
  font-size: 18px;
  font-weight: bold;
}
.containerDiv {
  padding: 4px;
  border: 1px solid gray;
  min-height: 43px;
  text-align: center;
  background-color: #e4dfdf;
}
.mainContainer{
  margin-bottom: 15px;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  
  z-index: 0 !important;

}

.ant-table-filter-dropdown .ant-dropdown-menu{
max-height: 300px !important;
z-index: 0 !important;
}


.Table tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
  width:"100%"
}

.Table th {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.Table th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.Table tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.Table tr:nth-child(even) {
  background: #7a8793 !important;
}

.Table tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
/* 
.Ant{
  color: white !important
}
.ant-btn
{
  background-color: #214972 !important;
  color:#fff !important;
  border-color: #1890ff !important;
}
.ant-btn ant-btn-primary{
  color: white !important
}

.ant-btn-primary{
  background-color: #214972 !important
}
.ant-btn-background-ghost.ant-btn-primary{
  color:white !important;
  background-color: #214972;
}
.ant-drawer-title{
  color:#214972 !important
}

.ant-btn ant-btn{
  color: white !important;
  background-color: #214972;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
  color:#214972
} */

.configDiv{
    background-color: #f0f0f0;
    border:1px solid #595959;
    border-radius: 10px;
    margin-top: 20px;
    width: 100%;
    padding-top: 15px;
   
}
.configButton{
    float: right;
    margin:0px 20px 20px;
}
.validButton{
    float: left;
    margin-left: 60px;
    margin-top: 20px;
}
.column-div{
  padding-left: 80px;
  padding-right: 80px;
}
.ant-form-item{
    margin-bottom: 0px !important;
}
.form-control{
    
    border:0.5px solid #d9d9d9;
margin-left: 30px;
height: 30px;
    /* padding-right: 50px; */
}
.btn-link{
    height: 20px;
    margin-left: 20px;
}
th.table-column{
    background-color : rgb(170, 163, 163) !important;
    text-align: center !important;
    font-weight: 900;

  }
  tr.table-column{
    text-align: center !important;
  }
  
th.table-column{
    background-color : rgb(170, 163, 163) !important;
    text-align: center !important;
    font-weight: 900;
  }
  tr.table-column{
    text-align: center !important;
  }
  
