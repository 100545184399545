.login-form {
    max-width: 400px;
   /* margin: 0px auto; */
    /* margin-top: 15% */
    /* display: flex; */
  }
  .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
  }

  .login-parent{
      display: flex;
  align-items: center;
  height:100vh;
  justify-content: center;
  }
 
  /* .test{
    margin-left: 40%;
    margin-top: 15%;
  } */
  .form-v1 .ant-row {
    display: block !important;
    /* flex-flow: row wrap; */
  }