.reportParentTable th.table-column{
    background-color :#214972!important;
    text-align: center !important;
    color: white !important;
  }
  .reportParentTable tr.table-column{
    text-align: center !important;
   
  }
  .reportChildTable tr > td{
    text-align: center !important;
    font-weight: bold !important;
  }
  .reportParentTable tr > td{
    text-align: center !important;
    font-weight: bold !important;
  }
  .reportParentTable tr > th {
  text-align: center !important;
  background-color :#214972!important;
  color: white !important;
  font-weight: bold !important;
 }
 .reportChildTable tr > th {
  text-align: center !important;
  background-color :#214972!important;
  font-weight: bold !important;

  color: white !important;
 }
 .reportChildTable tr:nth-child(odd){ 
  background: #efefef;
}
.reportChildTable tr:nth-child(even){
  background: #d9d9d9;
} 
.reportParentTable tr:nth-child(odd){ 
  background: #efefef !important;
}
.reportParentTable tr:nth-child(even){
  background: #d9d9d9  !important;
} 

.reportParentTable  .ant-table-tbody > tr > td {
  padding: 16px 0px !important;  
}

.reportParentTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.reportParentTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.reportParentTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
.reportChildTable  .ant-table-tbody > tr > td {
  padding: 16px 0px !important;
  
}

.reportChildTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.reportChildTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.reportChildTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
/* even: "#d9d9d9",
odd: "#efefef", */
.onlineStatus{
color: white;
font-size: 25px;
font-weight: bold;
height: 50px;
width: 200px;
border-radius: 8px;
background-color:green;
text-align: center;
float: right;
padding:5px;
margin-right:15px;
margin-bottom: 5px;
}
.offlineStatus{
  color: white;
  font-size: 25px;
  font-weight: bold;
  height: 50px;
  width: 200px;
  float: right;
  border-radius: 8px;
  background-color:#ff7875;
  text-align: center;
  padding:5px;
  margin-right:15px;
  margin-bottom: 5px;

}
.headerForm{
 
  margin-bottom: 8px;
  border-radius: 6px;
  padding: 9px;
}
.headerForm .ant-form-item{
  color: #124b84;
  font-weight: bold;
 

}
.headerForm  .ant-form-item-label > label {
  color: #124b84;
  font-weight: bold;
}
.headerColumn{
  /* border: 1px solid #124b84; */

}

.footerRow{

}
.footerCol{


  padding: 5px;
}
.footerRow .ant-form-item{
  color: #124b84;
  font-weight: bold;

}
.footerRow  .ant-form-item-label > label {
  color: #124b84;
  font-weight: bold;
}
.norecords{
  color: #fff;
  background-color: darkgrey;
  text-align: center;
  font-size:18px;
  font-weight: bold;
}
.warning_reports {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: white;
  font-size: 14px;
  text-align: center;
  background: #e4dfdf;
  border-radius: 0 0 4px 4px;
  border-radius: 8px;
}
.configure{
font-size: 18px;
color: gray;
text-align: center;
margin-top: 50px;
font-weight: bold;
}
.container {
  font-size: 18px;
  font-weight: bold;
}
.containerDiv {
  padding: 4px;
  border: 1px solid gray;
  min-height: 43px;
  text-align: center;
  background-color: #e4dfdf;
}
.mainContainer{
  margin-bottom: 15px;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  
  z-index: 0 !important;

}

.ant-table-filter-dropdown .ant-dropdown-menu{
max-height: 300px !important;
z-index: 0 !important;
}


.Table tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
  width:"100%"
}

.Table th {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.Table th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.Table tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.Table tr:nth-child(even) {
  background: #7a8793 !important;
}

.Table tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
/* 
.Ant{
  color: white !important
}
.ant-btn
{
  background-color: #214972 !important;
  color:#fff !important;
  border-color: #1890ff !important;
}
.ant-btn ant-btn-primary{
  color: white !important
}

.ant-btn-primary{
  background-color: #214972 !important
}
.ant-btn-background-ghost.ant-btn-primary{
  color:white !important;
  background-color: #214972;
}
.ant-drawer-title{
  color:#214972 !important
}

.ant-btn ant-btn{
  color: white !important;
  background-color: #214972;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
  color:#214972
} */
