 .importCard .ant-card-head{
    color: #000 !important;
    font-weight: bold;
   
    border-bottom: 2px solid #000 ;
}
.ant-card-body{
    padding:10px !important;
    font-weight: bold;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    font-weight: bold;;
}