.rdrDateRangePickerWrapper.PreviewArea {
  z-index: 1;
  position: absolute;
  margin-left: -337px;
}

.datebutton {
  background-color: #121212;
  border: none;
  color: white;
  height: 38px;
  margin-right: 12px;
  font-family: "Roboto-Medium";
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../CSS/Fonts/Roboto/Roboto-Medium.ttf");
}
