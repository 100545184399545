/* .headerdrag {
  background-color: #434343;
  border: 1px solid #595959;
} */

.headerdrag .editsettings {
  /* display: none; */
  background-color: #353535;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
}
.headerdrag:hover {
  background-color: #353535;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
}

.headerdrag:hover .editsettings {
  display: block;
}

.test {
  float: "right";
  color: "white";
  margin-top: "-25px";
  cursor: "pointer";
}

.headerdrag {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.headerdrag:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../CSS/Fonts/Roboto/Roboto-Medium.ttf");
}

.darkTheme .ant-modal-content {
  background-color: #000 !important;
  color: #fff !important;
  height: 950px;
}
.lightTheme .ant-modal-content {
  background-color: #fff !important;
  color: #000 !important;
  height: 950px;
}
.darkTheme .ant-modal-body {
  position: absolute;
  height: 100%;
  width: 100%;
}

.lightTheme .ant-modal-body {
  position: absolute;
  height: 100%;
  width: 100%;
}
.darkTheme .ant-modal-header {
  background-color: #000;
  color: #fff !important;
}
.darkTheme .ant-modal-header .ant-modal-title {
  color: #bfbfbf !important;
  font-weight: bold;
  font-size: 1.5em !important;
  text-align: center;
}
.lightTheme .ant-modal-header .ant-modal-title {
  color: #000 !important;
  font-weight: bold;
  font-size: 2em !important;
  text-align: center;
}
.darkTheme .ant-modal-close-x {
  display: block;
  width: 75px !important;
  height: 77px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: center;
  color: #fff;
}
.lightTheme .ant-modal-close-x {
  display: block;
  width: 75px !important;
  height: 77px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: center;
  color: #000;
}