@font-face {
  font-family: "Roboto-Medium";
  src: url("../../CSS/Fonts/Roboto/Roboto-Medium.ttf");
}

.spinner {
  display: flex;
  height: 800px;
  justify-content: center;
  align-items: center;
}


.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

/* Example stylistic flourishes */

.fileContainer {
  background: #ff4d4f;
  border-radius: 32px;
  float: left;
  padding: 0.5em;
  color: #ffffff;
  margin-left: 10px;
  width: 95px;
}

.fileContainer [type="file"] {
  cursor: pointer;
}
