.ant-card{
    color: #fff !important;
   
    font-size: 1.5em;
}
.ant-card-head{
    color: #fff !important;
    font-weight: bold;
   
    border-bottom: 2px solid #000 ;
}
 .ant-card-bordered {
    border: 0px solid #000;
} 