/* .TableWidget tr {
  background-color: #0d1115 !important;
}
.TableWidget th {
  color: white !important;
}

.TableWidget th {
  background-color: #06050561 !important;
}

.TableWidget tbody tr:hover td {
  background-color: #06050561 !important;
  cursor: pointer;
} */

.TableWidget tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.2em;
  font-weight: bold;
}
.TableWidget th {
  color: #ccc6c6 !important;
  text-align: center !important;
}

.TableWidget th {
  background-color: rgba(85, 84, 84, 0.65) !important;  
  /* border-bottom: none !important; */
  font-size: 1.1em;
  font-weight: 800;
}

.TableWidget tbody tr:hover td {
  background-color: #ddd !important;
  cursor: pointer;
}

.TableWidget tbody td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
  /* border-bottom: 1px solid #8c8c8c !important; */
  background-color: #ccc6c6 !important;
  font-size: 13px;
}

.TableWidget ant-table-thead {
  background-color: grey !important;
  cursor: pointer;
}




.TableLightWidget tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.1em;
}
.TableLightWidget th {
  color: white !important;
  text-align: center !important;
}

.TableLightWidget th {
  background-color: rgba(85, 84, 84, 0.65) !important;  
  /* border-bottom: none !important; */
  font-size: 1.1em;
  font-weight: 800;
}

.TableLightWidget tbody tr:hover td {
  /* background-color: #06050561 !important; */
  cursor: pointer;
}

.TableLightWidget tbody td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
  /* border-bottom: none !important; */
  font-size: 1.1em;
}

.TableLightWidget ant-table-thead {
  background-color: grey !important;
  cursor: pointer;
}

.TableLightWidget .ant-table-placeholder{
  background-color: #595959 !important;
}
.TableWidget .ant-table-placeholder{
  background-color: #595959 !important;
}
.TableText{
 width: 100px;
 /* 
 Note: The Below 4 Lines are necessary for ellipsis to work.
 */
 display: block;/* Change it as per your requirement. */
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}

.barTable tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.1em;
}
.barTable th {
  color: white !important;
  text-align: center !important;
}

.barTable th {
  background-color: rgba(85, 84, 84, 0.65) !important;
  border-bottom: none !important;
  font-size: 1.1em;
  font-weight: 800;
}

.barTable tbody tr:hover td {
  background-color: #d9d9d9 !important;
  cursor: pointer;
}

.barTable tbody tr td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
 background-color: #f5f5f5 !important;
  font-size: 1.1em;
}



.notificationMain{
  width: 251px;
 display: inline-block;
  height: 61px;
  margin-bottom: 20px;
  margin-right:2%;
  margin-left:2%;
  background:grey;
  border-radius: 10px;
  position: relative;
}
.notificationDiv{
  margin-top:5px;
  background-color: #d9d9d9;
  border-radius: 10px;
  width:100px;
  float:left;
  text-align: center;
  height:80%;
  margin:2%;

}

.notificationDiv1{
  margin-top:5px;
  background-color: #d9d9d9;
  border-radius: 10px;
  width:100px;
  
  text-align: center;
  height:80%;
  margin-left:50%;
  margin-top:2%;
  
  
}
.notificationDiv span{
  font-size: 2.5em;
}
.notificationDiv1 span{
  font-size: 2.5em;
}

