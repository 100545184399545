.trendsFields {
  font-weight: bold;
  margin-top: 6px;
  font-size: 16px
}

.trendTable tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
}

.trendTable th {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.trendTable th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.trendTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.trendTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.trendTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}

.radioGroup .ant-radio-wrapper {
  color: white;
  font-size: 16px;
}

.radioGroup .ant-radio {
  margin: 5px;
}