  .masterTable tr {
      background-color: #011931 !important;
      color: black !important;
      text-align: center !important;
  }

  .masterTable th {
      color: white !important;
      font-weight: bold !important;
      text-align: center !important;
  }

  .masterTable th {
      background-color: #214972 !important;
      font-weight: bold !important;
  }

  .masterTable tbody tr:hover td {
      background-color: #dedede !important;
      cursor: pointer;
      color: black !important;
  }

  .masterTable tr:nth-child(even) {
      background: #7a8793 !important;
  }

  .masterTable tr:nth-child(odd) {
      background: #bdc0c2 !important;
  }