body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .rowContents{
    border: solid #bbb 1px;
    padding: 10px 5px 10px 10px;
   width:40%;
   
   margin: 10px;
   background: #fff
  }
  
  .rowLabel{
    padding:0 0 0 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px
  }
  
  .innerdiv{
    padding: 10px 0 10px 50px ;
    background: #fff
  }
  
  .selectdiv{
    width: 350px  ;
    padding: 10px 0 0 20px;
    margin-left: 20px
  }
  
  .ant-input {
    /* width: 50px; */
    margin: 0 8px 8px 0;
  }
  
  .editInputs{
    width: 100%;
    padding: 4px 11px;
    background-color:#fff;
    height: 32px;
     
  
  }
  