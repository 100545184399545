.ant-progress-text {
  color: #bfbfbf !important;
}
.progressbarcircle {
  width: auto !important;
  height: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressbarline {
  width: auto !important;
  height: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.ant-progress.ant-progress-line {
  width: 80% !important;

  /* margin: 15%  !important; */
}
.ant-progress.ant-progress-circle {
  margin-top:5px;
  width: 40% !important;

  display: flex;
  justify-content: center;
  align-items: center;

  /* margin: 15%  !important; */
}
div[style].ant-progress-inner {
  width: 80% !important;
  height: auto !important;
}
/*  */
.ant-calendar-picker-input.ant-input {
  color: rgba(0, 0, 0, 0.9) !important;
}
.ant-calendar-range-picker-separator {
  color: rgba(0, 0, 0, 0.9) !important;
}
.ant-radio-group {
  color: rgba(0, 0, 0, 0.9) !important;
}
.ant-select-selection--single .ant-select-selection__rendered {
  color: rgba(0, 0, 0, 0.9) !important;
}
.warning-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  /* color: rgba(0, 0, 0, 0.25);*/
  color: rgba(0, 0, 0, 0.25); 
  font-size: 14px;
  text-align: center;
  background: transparent;
  border-radius: 0 0 4px 4px;
}