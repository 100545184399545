body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
.ant-layout-header {
  height: 64px;
  padding: -1px 50px;
  line-height: 64px;
  background: #001529;
} */

.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
  background: #384d62;
}


/* .ant-table table {
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    background: #d9ddb0;

} */
.ant-tabs-content {
  display: inline-block !important;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated{
  display:flex !important;
}
/* .ant-drawer-right{
  right:0 !important;
} */
.ant-drawer{
  inset:none !important;
  left:auto !important;
}
