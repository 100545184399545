


.liquidgauge {
    width: 100%;
    height: 90%;
    float: left;
  }
  @media (max-width: 980px) {
    .liquidgauge {
        height: 200px;
    }
  }
  
  
  
  
  .root-chart {
    width: 100%;
    height: 90%;
    float: none;
  }
/*   
  .nav {
    text-align: center;
    margin: 10px;
  }
  
  a {
    text-decoration: none;
    background-color: #294D99;
    transition: 0.5s;
    color: white;
    padding: 6px 18px;
    border-radius: 20px;
    margin: 0 4px;
  }
  
  a:hover {
    background-color: #000000;
  }
   */
  #view-more {
    text-align: center;
    margin: 20px 0 80px 0;
  }
  /* canvas {
    height: 100% !important;
    width: 100% !important;
  } */
  .liquidgauge>div {
    height: 100% !important;
    width: 100% !important;
  }