

.menu {
  float: right;
   margin-top: -62px;
   margin-right: -40px;
}

@media screen and (max-width: 1000px) {
  .menu {
      visibility: hidden;
  }
}

/* #components-layout-demo-fixed .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.ant-layout-header {
  height: 64px;
  line-height: 64px;
  background: #001529;
} */
.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
background-color: rgba(192,223,205,0.2); /*Overriden property*/
}
#components-layout-demo-fixed-sider .logo {
height: 32px;
background: rgba(255, 255, 255, 0.2);
margin: 16px;
}
.ant-menu{
font-size: 16px !important;
font-weight: bold !important;
}
/* .ant-layout-header{
padding:0 25px !important;
} */
.themeLayout  > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  margin-top: 18px !important;
}
.theme1 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #292961 !important;
  color:#fff !important;
}
.theme2 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #4b4ba3 !important;
  color:#fff !important;
}
.theme3 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #1a3652 !important;
  color:#fff !important;
}
.theme4 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #2261a1 !important;
  color:#fff !important;
}
.theme5 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #0d4524 !important;
  color:#fff !important;
}
.theme6 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #156b39 !important;
  color:#fff !important;
}
.theme7 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #691a16 !important;
  color:#fff !important;
}
.theme8 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #a62e21 !important;
  color:#fff !important;
}
.theme9 > .ant-layout-sider > .ant-layout-sider-children > .ant-menu{
  background-color: #2e2e2e !important;
  color:#fff !important;
}
.theme1 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #292961 !important;
  color:#fff !important;
}
.theme2 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #4b4ba3 !important;
  color:#fff !important;
}
.theme3 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #1a3652 !important;
  color:#fff !important;
}
.theme4 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #2261a1 !important;
  color:#fff !important;
}
.theme5 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #0d4524 !important;
  color:#fff !important;
}
.theme6 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #156b39 !important;
  color:#fff !important;
}
.theme7 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #691a16 !important;
  color:#fff !important;
}
.theme8 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #a62e21 !important;
  color:#fff !important;
}
.theme9 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #2e2e2e !important;
  color:#fff !important;
}
/* .theme8 > .ant-layout-sider > .ant-layout-sider-trigger{
  background-color: #a62e21 !important;
  border: 1px solid white !important;
  color:#fff !important;
} */