@font-face { font-family: "Digital-Plain"; src: url('./Fonts/Analog/digital-7.ttf'); }

@font-face { font-family: "Roboto-Regular"; src: url('./Fonts/Roboto/Roboto-Regular.ttf'); }

.ModalTable .ant-table-selection-column .ant-table-header-column{
    display: none !important;
}
.sldModalTable .ant-table-selection-column .ant-table-header-column{
    display: none !important;
}
.sldModalTable tr > th {
    text-align: center !important;
  font-weight: bold;
  font-size: 17px !important;
    color: white !important;
  }
  
  .sldModalTable tr:nth-child(odd) {
    background: #efefef !important;
  }
  .sldModalTable tr:nth-child(even) {
    background: #d9d9d9 !important;
  }
  
  .sldModalTable .ant-table-tbody > tr > td {
    text-align: center !important;
    padding: 16px 0px !important;
    font-weight: bold;
  font-size: 15px !important;
  }

  .sldModalTable th.table-column {
    background-color: #292961 !important;
    color: white !important;
  }
  .sldModalTable tr > th {
    background-color: #292961 !important;
    color: white !important;
  }

  
 /*themeing starts */

.theme1 th.table-column {
    background-color: #292961 !important;
    color: white !important;
  }
  .theme1 tr > th {
    background-color: #292961 !important;
    color: white !important;
  }
  .theme2 th.table-column {
    background-color: #4b4ba3 !important;
    color: white !important;
  }
  .theme2 tr > th {
    background-color: #4b4ba3 !important;
    color: white !important;
  }
  .theme3 th.table-column {
    background-color: #1a3652 !important;
    color: white !important;
  }
  .theme3 tr > th {
    background-color: #1a3652 !important;
    color: white !important;
  }
  .theme4 th.table-column {
    background-color: #2261a1 !important;
    color: white !important;
  }
  
  .theme4 tr > th {
    background-color: #2261a1 !important;
    color: white !important;
  }
  .theme5 th.table-column {
    background-color: #0d4524 !important;
    color: white !important;
  }
  .theme5 tr > th {
    background-color: #0d4524 !important;
    color: white !important;
  }
  .theme6 th.table-column {
    background-color: #156b39 !important;
    color: white !important;
  }
  .theme6 tr > th {
    background-color: #156b39 !important;
    color: white !important;
  }
  .theme7 th.table-column {
    background-color: #691a16 !important;
    color: white !important;
  }
  .theme7 tr > th {
    background-color: #691a16 !important;
    color: white !important;
  }
  .theme8 th.table-column {
    background-color: #a62e21 !important;
    color: white !important;
  }
  .theme8 tr > th {
    background-color: #a62e21 !important;
    color: white !important;
  }
  .theme9 th.table-column {
    background-color: #2e2e2e !important;
    color: white !important;
  }
  .theme9 tr > th {
    background-color: #2e2e2e !important;
    color: white !important;
  }
  /*themeing ends */