
.ant-card-cover > * {
    width: 1200px;
    height: 1200px;
}
.ant-card-cover {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    background: #191a1d;
    
}

/* .ant-card-actions > li:not(:last-child) {
    border-right: 1px solid #191a1d;
    color: black;
} */

.ant-card-actions{
background: #fafafa;
zoom: 1;
list-style: none;
margin: 0;
padding: 0;
}

.iframe-wrap {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    background: #191a1d;
}

.cover-link {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: 0 !important;
    z-index: 1;
}
.visually-hidden {
    position: absolute !important;
    top: -9999px;
    left: -9999px;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    color: #1890ff;
    margin: 0;
    padding: 0;
  }
  
  .link-button:hover,
  .link-button:focus {
  text-decoration: none;
  color: #40a9ff;
  }